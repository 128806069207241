@tailwind base;
@tailwind components;
@tailwind utilities;


@media (max-width: 768px) {
  body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
html, body {
  overflow-x:hidden;
  width:100%;
}

.googlePlay {
    background-image: url(https://www.ishtari.com/img/sprite.25850177.png);
    background-position-x: 0px;
    background-position-y: -86px;
    background-size: 186px;
  
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    text-indent: 100%;
    white-space: nowrap;
    display: inline-block;
    width: 135px;
    padding: 0 20px;
    height: 40px;
  }

  
  .appleStore {
    display: inline-block;
    width: 135px;
    height: 40px;
    background-image: url(https://www.ishtari.com/img/sprite.25850177.png);
    background-position-x: 0px;
    background-position-y: 0px;
    background-size: 186px;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
  /* Slider */
.slick-loading .slick-list 
{
}

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
}
/* Arrows */

.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
}


.slick-prev
{
    left: -50px;
}


/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 0.2;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .5;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .2;
    color: rgba(255,255,255,0.7);
}



/* Slider */
.slick-slider
{
    position: relative; 
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
    gap: 2rem;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-dots {
    position: relative;
    bottom: 10px !important;
}




.slick-slide {
    outline: none;
}

.slick-dots {
    bottom: -5px;
}

.carousel.nobg  .slick-dots li button:before {
    font-family: unset;
    font-size: 120px;
    line-height: 0;
    position: absolute;
    top: -70px;
    left: 0;
    width: 20px;
    height: 20px;
    content: ".";
    text-align: center;
    opacity: 0.2;
    color: black;
}

.carousel.bg .slick-dots li button:before {
  font-family: unset;
  font-size: 120px;
  line-height: 0;
  position: absolute;
  top: -70px;
  left: 0;
  width: 20px;
  height: 20px;
  content: ".";
  text-align: center;
  opacity: 0.2;
  color: white;
}

.carousel .slick-dots li button:before {
    top: -20px !important;
    opacity: 0.4;

}

.carousel.nobg .slick-dots li.slick-active button:before{
    color: black;
    opacity: 1;

}

.carousel.bg .slick-dots li.slick-active button:before{
  color: white;
  opacity: 1;

}

.slick-dots li.slick-active button:before {
    opacity: 1;
    /* @apply text-dbase; */
}

.slick-dots li {
    margin: 0 5px;
}

.slick-track {
    margin-left: 0;
}

.selectors .slick-list{
    display: flex;
}


.selectors .slick-slide {
    width: 100px;
    margin-inline: 4px;
 }
 .selectors .slick-track {
    width: unset !important;
    display: flex;
    justify-content: center;
 }


 #selector_div .slick-slide {
    width: 100px !important;
 }
 .cart_slider .slick-next{
    top: unset ;
    bottom: -5px !important ;
    left: 50% ;
 }
 .cart_slider .slick-prev{
    top: -13px !important ;
    left: 50% ;
 }

 .cart_slider .slick-slider .slick-track{
    height: unset !important;
  }
  .cart_slider .slick-disabled {
    display: none !important;
  }

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */

input[type="number"] {
 -moz-appearance: textfield;
}

.address-modal__input{
    width: 100%;
    height: 35px;
    padding: 0 10px;
    margin-top: 5px;
    background: #fff;
    border: solid 1px #e8e8e8 ;
    resize: none;
    outline: none;
}

.ldds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .ldds-ellipsis div {
    position: absolute;
    top: 33px;
    text-align: center;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    /* @apply bg-dbase; */
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .ldds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: ldds-ellipsis1 0.6s infinite;
  }
  .ldds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: ldds-ellipsis2 0.6s infinite;
  }
  .ldds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: ldds-ellipsis2 0.6s infinite;
  }
  .ldds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: ldds-ellipsis3 0.6s infinite;
  }
  @keyframes ldds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes ldds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes ldds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  .pagination {
    @apply flex justify-center w-full my-4;
  }
  
  .pagination li {
    @apply mr-0 md:mr-2;
  }
  
  .pagination li a {
    @apply block w-12 h-9 text-center leading-9 text-dgrey6 cursor-pointer border border-transparent;
  }
  
  .pagination li a:hover,
  .active-pagination a {
    @apply bg-white border border-dblack1 !important;
  }


  .thumbs-slider .slick-list {margin: 0 -2px;}
  .thumbs-slider .slick-slide>div {padding: 0 2px; width:95px;}

  @media (max-width: 1024px){
    .thumbs-slider .slick-list {margin: 0 -8px;}
    .thumbs-slider .slick-slide>div {padding: 0 8px; width:95px;}
  }

  @media (max-width: 650px){
    .thumbs-slider .slick-list {margin: 0 -2px;}
    .thumbs-slider .slick-slide>div {padding: 0 2px; width:95px;}
  }
  @media (max-width: 840px){
    .thumbss-slider  .slick-current>div>div{
      width: 77px !important; 
    }
  
    .thumbss-center-slider .slick-track {
      place-items: center;
      display: flex;
      justify-content: center !important;
      align-items: center;
      width: 100% !important;
    }
  }

  .img-zoom-lens {
    position: absolute;
    border: 1px solid transparent;
    /*set the size of the lens:*/
    width: 140px;
    height: 140px;
  }
  
  .img-zoom-result {
    border: 1px solid #d4d4d4;
    /*set the size of the result div:*/
    width: 440px;
    height: 437px;
  }

  .myimage-product-zoom{
    transition: opacity 0.25s ease-in-out;
  }

  @media (max-width: 840px){
     #popup_modal::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 130%;
    height: 100%;
    overflow-y: hidden;
    margin-left: -30px;
    margin-top: -15px;

    background-size: cover;
    filter: blur(30px);
    z-index: -1;
    
  }
  }
  @media (max-width: 840px){
    .popup-open {
      overflow-y: hidden !important;
      position: fixed;
      width: 100%;
    }
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .placeholder_animation {
      background-image: radial-gradient(
        rgba(254, 254, 254, 0.925),
        rgba(229, 227, 232, 0.642)
      );
      /* background-image: radial-gradient(rgba(254, 254, 254, 0.925), rgba(210, 210, 210, 0.323)); */
      /* background-image: radial-gradient(
        
        rgba(255, 255, 255, 0) 0px,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.7) 60%,
        rgba(255, 255, 255, 0) 100%,
  
      ); */
      background-size: 200% 100%;
      animation: anmBg 0.8s linear infinite;
    }
    @keyframes anmBg {
      to {
        background-position: -170% 0;
      }
    }
    @media (max-width: 650px) {
      .placeholder_animation {
        background-image: radial-gradient(
          rgba(254, 254, 254, 0.925),
          rgba(215, 211, 223, 0.474)
        );
      }
    }
  }
  
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .placeholder_animation_latest {
      background-image: radial-gradient(
        rgba(254, 254, 254, 0.925),
        rgba(218, 216, 220, 0.642)
      );
      /* background-image: radial-gradient(rgba(254, 254, 254, 0.925), rgba(210, 210, 210, 0.323)); */
      /* background-image: radial-gradient(
        
        rgba(255, 255, 255, 0) 0px,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.7) 60%,
        rgba(255, 255, 255, 0) 100%,
  
      ); */
      background-size: 200% 100%;
      animation: anmBg 0.8s linear infinite;
    }
    @keyframes anmBg {
      to {
        background-position: -170% 0;
      }
    }
    @media (max-width: 650px) {
      .placeholder_animation {
        background-image: radial-gradient(
          rgba(254, 254, 254, 0.925),
          rgba(215, 211, 223, 0.474)
        );
      }
    }
  }


