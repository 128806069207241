@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Lora', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.center_div_inside_div{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.header__basket__item__img{
    display: block;
    width: 66px;
    height: 100px;
    background-size: cover;
    border: 1px solid #ebebeb;
    background-position: 50%;
    /* background-image: url(https://akn-eh.b-cdn.net/products/2022/09/16/223551/b316c024-9676-4f0e-a704-16a56dfc81ec_size120x120.jpg); */

}
 
.category-pagination {
  @apply flex justify-center w-full my-4;
}

.category-pagination li {
  margin: 0px 5px;
}

.category-pagination li a {
  padding: 5px 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 200;
  color: rgb(126, 133, 155);
  text-align: center;
  font-size: 1.3333rem;
  text-decoration: none;
}

.category-pagination li a:hover,
.active-pagination-category a {
  border-radius: 3px;
  color: rgb(56 102 223) !important;
  background-color: white;
  box-shadow: rgb(56 102 223) 0px 0px 1px 1px;
}


/* .slick-prev, .slick-next {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.slick-prev {
  left: -50px; 
}

.slick-next {
  right: -50px; 
} */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
}
.font{
  font-family: sans-serif, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

